// 需要和 src/components/Device/ 保持一致

@mixin mediaS {
  @media (max-width: 720px) {
    @content;
  }
}

@mixin mediaM {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin mediaML {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin mediaL {
  @media (min-width: 721px) {
    @content;
  }
}
@mixin mediaX {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin mediaXL {
  @media (min-width: 1600px) {
    @content;
  }
}
