.modal {
  box-sizing: border-box;
  color: #484c70;

  .returnImg {
    position: absolute;
    left: 16px;
    top: 20px;
    cursor: pointer;
    z-index: 100;
  }

  .closeImg {
    position: absolute;
    right: 16px;
    top: 20px;
    cursor: pointer;
    z-index: 100;
  }

  .content {
    padding: 20px;
  }

  :global {
    .rc-dialog-close {
      display: none;
    }

    .rc-dialog-content {
      border-radius: 12px;
    }

    .rc-dialog-body {
      position: relative;
      padding: 16px;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
}

.confirmFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  .confirmBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #1b1f4d;
    border-radius: 999px;
    box-sizing: border-box;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    cursor: pointer;
  }
}
